<template lang="">
  <div>
    <b-modal
      :id="id"
      size="lg"
      centered
      hide-footer
      :title="title"
    >
      <span v-html="dataDetail.content">

      </span></b-modal>
  </div>
</template>
<script>

import {
  BModal,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,

  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    dataDetail: {
      type: Object,
      default: _ => {},
    },
  },
}
</script>
<style lang="scss">
  .btn-down {
    display: grid;
    margin-bottom: 15px;
    align-items: end;
  }
</style>
